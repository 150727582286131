import { useMemo } from 'react';
import clsx from 'clsx';
import { Button } from '@sportscardinvestor/sci-ui/components/buttons/button';
import { IconButton } from '@sportscardinvestor/sci-ui/components/buttons/icon-button';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import FieldLabelBox, { FieldLabelBoxProps } from '../../forms/FieldLabelBox/FieldLabelBox';
import SimpleSelect, { Option } from '../../forms/SimpleSelect/SimpleSelect';

export interface CategoryFilterProps<TCategory, TId extends string | number = number> {
  className?: string;
  categories: TCategory[] | undefined;
  selectedCategoryId: TId | null;
  onChange: (newCategoryId: TId | null) => void;
  id: string;
  error?: string;
  noValueLabel?: string;
  getLabel: (category: TCategory) => string;
  getId: (category: TCategory) => TId;
  label?: string;
  notEmpty?: boolean;
  inline?: boolean;
  variant?: FieldLabelBoxProps['variant'];
  onEditCategories?: () => Promise<{ categoryId: TId | null }>;
}

export function CategoryFilter<TCategory, TId extends string | number = number>({
  className,
  categories = [],
  selectedCategoryId,
  onChange,
  id: filterId,
  error,
  noValueLabel = 'All',
  getId,
  getLabel,
  label = 'Category',
  notEmpty = false,
  inline = false,
  variant,
  onEditCategories,
}: CategoryFilterProps<TCategory, TId>) {
  const options = useMemo(() => {
    return (
      categories?.map<Option<TId>>((category) => ({
        value: getId(category),
        label: getLabel(category),
      })) ?? []
    );
  }, [categories, getId, getLabel]);
  const id = inline ? filterId : `${filterId}-category`;

  const handleEdit = async () => {
    if (!onEditCategories) {
      return;
    }
    const { categoryId } = await onEditCategories();
    if (categoryId) {
      onChange(categoryId);
    }
  };

  return (
    <FieldLabelBox
      label={inline ? undefined : label}
      fieldId={id}
      className={clsx('twp', className)}
      error={error}
      adornmentRight={
        !!onEditCategories && !inline ? (
          <Button variant="secondary-link" size="small" shape="round" onPress={handleEdit}>
            Edit
          </Button>
        ) : null
      }
      variant={variant}
      contentClassName="flex flex-row items-center gap-2"
    >
      <SimpleSelect
        className="grow shrink overflow-hidden"
        options={options}
        value={selectedCategoryId}
        onChange={onChange}
        noValueLabel={noValueLabel}
        id={id}
        notEmpty={notEmpty}
      />
      {!!inline && !!handleEdit && (
        <IconButton
          className="grow-0 shrink-0"
          faIcon={faPencil}
          title="Add New"
          onClick={handleEdit}
          size="medium"
          variant="default"
          shape="round"
        />
      )}
    </FieldLabelBox>
  );
}
