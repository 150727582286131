import { useMemo } from 'react';
import { CollectionCategory } from '@sportscardinvestor/schemas';
import {
  CategoryFilter,
  CategoryFilterProps,
} from '../../sci-ui-components/collectibles/CategoryFilter/CategoryFilter';
import { CollectibleType } from '../../sci-ui-components/types/collectibleType';
import { collectibleNames } from '../../sci-ui-components/collectibles/constants';
import { NoCategory } from '../../services/sciApi/collections/types';
import useCollectionCategories from './useCollectionCategories';
import { showManageCollectionCategoriesDialog } from './ManageCollectionCategoriesDialog/store';

interface CategoryOption {
  id: number;
  name: string;
}

export function CollectionCategoryFilter({
  collectibleType,
  noValueLabel = `All ${collectibleNames[collectibleType].shortPlural}`,
  selectedCategoryId,
  withNone = false,
  ...other
}: Omit<CategoryFilterProps<CollectionCategory, number>, 'getId' | 'getLabel' | 'categories' | 'onEditCategories'> & {
  collectibleType: CollectibleType;
  withNone?: boolean;
}) {
  const { items } = useCollectionCategories({ collectibleType });

  const categoryOptions = useMemo(
    () =>
      items.reduce<CategoryOption[]>(
        (acc, item) => {
          acc.push({
            id: item.id,
            name: item.name,
          });
          return acc;
        },
        withNone
          ? [
              {
                id: NoCategory,
                name: 'No Category',
              },
            ]
          : []
      ) ?? [],
    [items, withNone]
  );

  return (
    <CategoryFilter
      {...other}
      selectedCategoryId={selectedCategoryId}
      noValueLabel={noValueLabel}
      getId={({ id }) => id}
      getLabel={({ name }) => name}
      categories={categoryOptions}
      onEditCategories={async () => showManageCollectionCategoriesDialog({ collectibleType })}
    />
  );
}
