import { mmApiClient, MmApiInput, MmApiOutput, MmApiQueryOptions, useAuthenticatedMMAPIQuery } from '@/services/mmApiX';
import {
  CollectibleType,
  CollectionDimension,
  isCollectionDimensionSealedWax,
  isCollectionDimensionSportsCard,
} from '@sportscardinvestor/schemas';
import { NoCategory } from './constants';

export type CollectionDimensionStatsQueryInput = Exclude<MmApiInput['private']['collection']['dimensionStats'], void>;
export type CollectionDimensionStatsInput = Omit<
  CollectionDimensionStatsQueryInput,
  'dimension' | 'collectibleType'
> & {
  dimension: CollectionDimension;
  collectibleType: CollectibleType;
};
export type CollectionDimensionStatsOutput = Exclude<MmApiOutput['private']['collection']['dimensionStats'], void>;
export type { CollectionDimension, CollectionDimensionStats } from '@sportscardinvestor/schemas';

export const useCollectionDimensionStatsKey = 'private.collection.dimensionStats';
type QueryKey = [typeof useCollectionDimensionStatsKey, CollectionDimensionStatsQueryInput];

export const getUseCollectionDimensionStatsQueryKey = (input: CollectionDimensionStatsQueryInput): QueryKey => [
  useCollectionDimensionStatsKey,
  input,
];

export function useCollectionDimensionStats(
  { dimension, collectibleType, ...baseInput }: CollectionDimensionStatsInput,
  options?: MmApiQueryOptions<CollectionDimensionStatsOutput, QueryKey>
) {
  const input: CollectionDimensionStatsQueryInput =
    collectibleType === 'sealed-wax-card'
      ? {
          ...baseInput,
          collectibleType,
          dimension: isCollectionDimensionSealedWax(dimension) ? dimension : 'set',
        }
      : {
          ...baseInput,
          collectibleType,
          dimension: isCollectionDimensionSportsCard(dimension) ? dimension : 'set',
        };
  const itemsInput: CollectionDimensionStatsQueryInput = {
    ...input,
    filters: {
      ...input.filters,
      categoryId: input.filters?.categoryId === NoCategory ? 'noCategory' : input.filters?.categoryId,
    },
    offset: input.offset ?? 0,
  };
  const { data: { items } = {}, ...otherItemsResult } = useAuthenticatedMMAPIQuery(
    getUseCollectionDimensionStatsQueryKey(itemsInput),
    () => mmApiClient.private.collection.dimensionStats.query(itemsInput),
    options
  );
  const totalCountInput: CollectionDimensionStatsQueryInput = {
    ...itemsInput,
    offset: 0,
  };
  const { data: { totalCount = 0 } = {} } = useAuthenticatedMMAPIQuery(
    getUseCollectionDimensionStatsQueryKey(totalCountInput),
    () => mmApiClient.private.collection.dimensionStats.query(totalCountInput),
    options
  );

  return {
    ...otherItemsResult,
    items,
    totalCount,
  };
}
