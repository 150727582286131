import { mmApiClient, MmApiInput, MmApiOutput, MmApiQueryOptions, useAuthenticatedMMAPIQuery } from '@/services/mmApiX';
import { CollectibleType } from '@sportscardinvestor/schemas';

type ListCollectionCategoriesQueryInput = Exclude<MmApiInput['private']['collection']['categories']['list'], void>;
export interface ListCollectionCategoriesInput {
  collectibleType: CollectibleType;
}
export type ListCollectionCategoriesOutput = Exclude<MmApiOutput['private']['collection']['categories']['list'], void>;

export const useListCollectionCategoriesKeyPrefix = 'private.collection.categories.list';
type QueryKey = [typeof useListCollectionCategoriesKeyPrefix, ListCollectionCategoriesQueryInput];

export function makeListCollectionCategoriesQueryKey(params: ListCollectionCategoriesInput): QueryKey {
  return [useListCollectionCategoriesKeyPrefix, makeListCollectionCategoriesInput(params)];
}

function makeListCollectionCategoriesInput({
  collectibleType,
}: ListCollectionCategoriesInput): ListCollectionCategoriesQueryInput {
  return {
    filters: {
      collectibleType,
    },
    sort: [
      {
        sortBy: 'name',
        sortDirection: 'asc',
      },
    ],
    limit: 1000,
    offset: 0,
  };
}

export default function useCollectionCategories(
  inputParam: ListCollectionCategoriesInput,
  options?: MmApiQueryOptions<ListCollectionCategoriesOutput, QueryKey>
) {
  const input = makeListCollectionCategoriesInput(inputParam);
  const queryKey: QueryKey = [useListCollectionCategoriesKeyPrefix, input];
  const { data, ...result } = useAuthenticatedMMAPIQuery(
    queryKey,
    () => mmApiClient.private.collection.categories.list.query(input),
    options
  );

  return {
    items: data?.items ?? [],
    ...result,
  };
}
