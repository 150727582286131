import { create } from 'zustand';
import type { OnManageCollectionCategoriesSuccessFn, OnManageCollectionCategoriesSuccessPayload } from './types';
import { CollectibleType } from '@sportscardinvestor/schemas';
import { trackEvent } from '@/features/analytics/trackEvent';

interface ManageCollectionCategoriesDialogState {
  isOpen: boolean;
  collectibleType: CollectibleType;
  close: () => void;
  callback: OnManageCollectionCategoriesSuccessFn | null;
}

export const useManageCollectionCategoriesDialogStore = create<ManageCollectionCategoriesDialogState>()((set) => ({
  isOpen: false,
  collectibleType: 'sports-card',
  callback: null,
  close: () => set({ isOpen: false, collectibleType: 'sports-card', callback: null }),
}));

export function showManageCollectionCategoriesDialog({ collectibleType }: { collectibleType: CollectibleType }) {
  const { promise, resolve } = Promise.withResolvers<OnManageCollectionCategoriesSuccessPayload>();
  useManageCollectionCategoriesDialogStore.setState({
    isOpen: true,
    collectibleType,
    callback: resolve,
  });
  trackEvent({
    eventName: 'COLLECTION_CATEGORIES_EDIT_STARTED',
    collectibleType,
  });
  return promise;
}
