import { ExportToCsv } from 'export-to-csv';
import {
  getCollectibleBoxTypeName,
  getCollectibleCardNumber,
  getCollectibleGradeName,
  getCollectiblePlayerName,
  getCollectibleSetName,
  getCollectibleSetYear,
  getCollectibleSpecificQualifier,
  getCollectibleSportName,
  getCollectibleVariationName,
} from '@sportscardinvestor/collectible-helpers';
import LoadingSpinner from '../../../sci-ui-components/LoadingSpinner/LoadingSpinner';
import { CollectionImportDataKey, cardKeys, waxKeys } from '../../../sci-ui-components/types/collectionImport';
import { getCollectibleHeaders, collectionExportOnlyCsvHeaders, CollectionExportOnlyDataKey } from './helpers';

import classes from './CollectionExport.module.scss';
import { formatDate } from 'sci-ui-components/utils/date';
import { collectibleNames } from 'sci-ui-components/collectibles/constants';
import useCollectibleType from 'features/collectibles/useCollectibleType';
import { useFetchListCollectionItems_deprecated } from '@/features/collection/deprecated_useListCollectionItems';

interface Props {
  children: React.ReactNode;
}

export default function CollectionExport({ children }: Props) {
  const { collectibleType } = useCollectibleType();
  const { fetchListCollectionItems, isLoading } = useFetchListCollectionItems_deprecated();

  const exportCsv = async () => {
    const { items } = await fetchListCollectionItems({
      filters: {
        collectibleType,
      },
    });
    const sortedItems = [...(items ?? []).filter((v) => !v.dateSold), ...(items ?? []).filter((v) => !!v.dateSold)]; // NOTE: sold at the end

    const data = sortedItems
      .map<Record<CollectionExportOnlyDataKey | CollectionImportDataKey, string>>((item) => {
        const collectible = item.collectible ?? item.customCollectible;
        if (!collectible) {
          throw new Error('Missing collectible data');
        }
        const sportName = getCollectibleSportName(collectible) ?? '';
        const setYear = getCollectibleSetYear(collectible) ?? '';
        const setName = getCollectibleSetName(collectible) ?? '';
        return {
          quantity: String(item.quantity),
          'date-purchased': item.datePurchased ?? '',
          'purchase-price-per-card': String(item.purchasePricePerItem),
          notes: item.note?.replace(',', '') ?? '',
          category: item.collectionCategories?.[0]?.name ?? '',
          'date-sold': item.dateSold ?? '',
          'sold-price': `${item.soldPricePerItem ?? ''}`,
          'last-sale-price': `${item.currentValuePerItem ?? ''}`,
          'last-sale-date': item.collectible?.stats?.lastSaleDate
            ? formatDate(item.collectible.stats.lastSaleDate) ?? ''
            : '',
          sport: sportName,
          'player-name': getCollectiblePlayerName(collectible) ?? '',
          'box-type': getCollectibleBoxTypeName(collectible) ?? '',
          year: setYear,
          set: setName,
          variation: getCollectibleVariationName(collectible) ?? '',
          grade: getCollectibleGradeName(collectible) ?? '',
          'card-number': getCollectibleCardNumber(collectible) ?? '',
          'specific-qualifier': getCollectibleSpecificQualifier(collectible) ?? '',
        };
      })
      .flat();
    const collectibleHeaders = { ...getCollectibleHeaders(collectibleType), ...collectionExportOnlyCsvHeaders };
    (collectibleType === 'sealed-wax-card' ? cardKeys : waxKeys).forEach((key) => {
      delete collectibleHeaders[key];
    });
    data.splice(0, 0, collectibleHeaders as (typeof data)[number]);
    const csvExporter = new ExportToCsv({
      title: 'Collection Export',
      filename: `${collectibleNames[collectibleType].shortPlural.toLowerCase()}-collection-export`,
    });
    csvExporter.generateCsv(data);
  };

  if (isLoading) return <LoadingSpinner className={classes.loadingIndicator}></LoadingSpinner>;

  return (
    <button className={classes.menuButton} onClick={exportCsv}>
      {children}
    </button>
  );
}
