import { useMemo } from 'react';
import { mmApiClient, MmApiInput, MmApiOutput, MmApiQueryOptions, useAuthenticatedMMAPIQuery } from '@/services/mmApiX';
import { NoCategory } from './constants';

export type CollectionStatsInput = Exclude<MmApiInput['private']['collection']['stats'], void>;
export type CollectionStatsOutput = Exclude<MmApiOutput['private']['collection']['stats'], void>;

export const useCollectionStatsKey = 'private.collection.stats';
type QueryKey = [
  typeof useCollectionStatsKey,
  CollectionStatsInput['filters']['collectibleType'],
  CollectionStatsInput
];

export const getUseCollectionStatsQueryKey = (input: CollectionStatsInput): QueryKey => [
  useCollectionStatsKey,
  input.filters.collectibleType,
  input,
];

export function useCollectionStats(
  inputParam: CollectionStatsInput,
  options?: MmApiQueryOptions<CollectionStatsOutput, QueryKey>
) {
  const input: CollectionStatsInput = {
    ...inputParam,
    filters: {
      ...inputParam.filters,
      categoryId: inputParam.filters?.categoryId === NoCategory ? 'noCategory' : inputParam.filters?.categoryId,
    },
  };
  const result = useAuthenticatedMMAPIQuery(
    getUseCollectionStatsQueryKey(input),
    () => mmApiClient.private.collection.stats.query(input),
    options
  );

  return result;
}

export function useGlobalCollectionStats() {
  const {
    data: cardStats,
    isLoading: isLoadingCardCollectionStats,
    ...cardCollectionQuery
  } = useCollectionStats({
    filters: {
      collectibleType: 'sports-card',
    },
  });

  const {
    data: waxStats,
    isLoading: isLoadingWaxollectionStats,
    ...waxCollectionQuery
  } = useCollectionStats({
    filters: {
      collectibleType: 'sealed-wax-card',
    },
  });

  const isLoading = isLoadingCardCollectionStats || isLoadingWaxollectionStats;

  const globalStats = useMemo<CollectionStatsOutput | null>(() => {
    if (isLoading || !cardStats || !waxStats) {
      return null;
    }
    return {
      ownedItemsTotalValue: cardStats.ownedItemsTotalValue + waxStats.ownedItemsTotalValue,
      ownedItemsTotalPaid: cardStats.ownedItemsTotalPaid + waxStats.ownedItemsTotalPaid,
      totalSalesValue: cardStats.totalSalesValue + waxStats.totalSalesValue,
      totalSaleFees: cardStats.totalSaleFees + waxStats.totalSaleFees,
      numberOfSoldItems: cardStats.numberOfSoldItems + waxStats.numberOfSoldItems,
      numberOfOwnedItems: cardStats.numberOfOwnedItems + waxStats.numberOfOwnedItems,
      ownedItemsNetValue: cardStats.ownedItemsNetValue + waxStats.ownedItemsNetValue,
      salesNetProfitOrLoss: cardStats.salesNetProfitOrLoss + waxStats.salesNetProfitOrLoss,
      numberOfOwnedUniqueCollectibles:
        cardStats.numberOfOwnedUniqueCollectibles + waxStats.numberOfOwnedUniqueCollectibles,
    };
  }, [cardStats, waxStats, isLoading]);

  return {
    cardStats,
    waxStats,
    globalStats,
    isLoading,
    cardCollectionQuery,
    waxCollectionQuery,
  };
}
